import './Lightbox.css';

export default function Lightbox ({ className, open, onClose, style, children }) {
  
  return (
    <div className={`${className} lightbox-container${open !== true ? " invisible" : ""}`}>
      {/* Conteneur principal du lightbox, visible seulement si 'open' est vrai */}
      <div className="lightbox-inner" onClick={onClose}>
        {/* Cadre de contenu, on arrête la propagation du clic pour éviter de fermer le lightbox en appuyant à l'intérieur du cadre */}
        <div className="lightbox" style={style} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
};