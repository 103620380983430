import './Footer.css';
import { setLanguage, getLanguage } from '../TextManager';

export default function Footer() {

  return (

    <div className="app-footer">
      <div className="footer-left">
        <div className="languages">
          {/* Sélecteur de langue - Anglais */}
          <div className={"language" + (getLanguage() === "en" ? " selected" : "")} id="english-language">
          {
            getLanguage() === "en" ?
            // Si la langue actuelle est l'anglais, afficher l'image sans lien
            <img src="/images/flag-eng.png" alt="English"/> :
            // Sinon, afficher un lien pour changer la langue en anglais
            <a href={`${window.location.protocol}//${window.location.host}`} onClick={() => setLanguage('en')}>
              <img src="/images/flag-eng.png" alt="English"/>
            </a>
          }
          </div>
          {/* Sélecteur de langue - Français */}
          <div className={"language" + (getLanguage() === "fr" ? " selected" : "")} id="french-language">
          {
            getLanguage() === "fr" ?
            // Si la langue actuelle est le français, afficher l'image sans lien
            <img src="/images/flag-fr.png" alt="Français"/> :
            // Sinon, afficher un lien pour changer la langue en français
            <a href={`${window.location.protocol}//${window.location.host}`} onClick={() => setLanguage('fr')}>
              <img src="/images/flag-fr.png" alt="Français"/>
            </a>
          }
          </div>
        </div>
      </div>
      <div className="footer-right">
        <div className="socials">
          <div className="socials-list">
            {/* Lien vers la page Facebook */}
            <div className="social-link">
              <a href="https://www.facebook.com/invocaster" target="_blank" rel="noreferrer">
                <img src="/images/facebook-icon.png" alt="Facebook link"/>
              </a>
            </div>
            {/* Lien vers la page Instagram */}
            <div className="social-link">
              <a href="https://www.instagram.com/invocaster" target="_blank" rel="noreferrer">
                <img src="/images/instagram-icon.png" alt="Instagram link"/>
              </a>
            </div>
            {/* Lien vers la page X (anciennement Twitter) */}
            <div className="social-link">
              <a href="https://x.com/Invocaster" target="_blank" rel="noreferrer">
                <img src="/images/x-icon.png" alt="X link"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}