import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';

// Création de la racine pour l'application React
// Le code va être intégré dans la balise <root> de /index.html
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendu du composant principal dans le DOM
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);