import './App.css';
import Home from './Home';
import Footer from './Footer';
import { useEffect } from 'react';
import { setLanguage } from '../TextManager';

export default function App() {

  useEffect(() => {
    // Définition de la langue en fonction du chemin de l'URL
    switch (window.location.pathname) {
      case '/en': 
        setLanguage('en');
        break;
      case '/fr': 
        setLanguage('fr');
        break;
      default: 
        break;
    }
  }, []); // Le tableau de dépendances vide garantit que ce code ne s'exécute qu'une seule fois lors du montage du composant.

  return (
    <div className="app">
      {/* Image de fond de l'application */}
      <img className="app-background" src="/images/invocaster-background_2000px.jpg" alt="Background"/>
      {/* Composant principal de la page d'accueil */}
      <Home/>
      {/* Pied de page de l'application */}
      <Footer/>
    </div>
  );
}