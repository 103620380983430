import './FadeIn.css';
import { useEffect, useRef } from 'react';

export default function FadeIn({ children, left, right, center, reload }) {
  const fadeInRef = useRef(null); // Référence au conteneur qui devra effectuer l'animation fade in

  useEffect(() => {
    const currentRef = fadeInRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Lorsque l'élément entre dans le viewport, on applique les styles pour faire apparaître l'animation
            entry.target.style.opacity = 1;
            entry.target.style.transform = "translateX(0) scale(1)";
            observer.unobserve(entry.target); // Arrête d'observer une fois que l'animation est déclenchée
            if (reload) {
              // Si 'reload' est activé, réinitialiser certaines animations
              setTimeout(() => {
                const elements = document.querySelectorAll('.rolling-section:not(.backwards) .rule-image');
                elements.forEach(e => e.style.animation = "unset");
                setTimeout(() => {
                  elements.forEach(e => e.style.animation = "rolling-section-translate linear infinite 100s");
                  elements[1].style.animationDelay = "-50s";
                }, 10);
              }, 10);
            }
          }
        });
      },
      { threshold: 0.2 } // Déclencher l'animation quand 10% de l'élément est visible
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [reload]);

  return (
    // Classe dynamique pour spécifier la direction de l'animation
    <div className={"fadein" + (left ? " left" : "") + (right ? " right" : "") + (center ? " center" : "")} ref={fadeInRef}>
      { children }
    </div>
  );
}