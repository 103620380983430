import './Home.css';
import { useState } from 'react';
import { read } from '../TextManager';
import Separator from './Separator';
import FadeIn from './FadeIn';
import Lightbox from './Lightbox';

function format(text) {
  const parts = text.split(/(\[.*?\]|\n)/);

  return (
    <span>
      {parts.map((part, index) => {
        if (part === '\n') {
          return <br key={index} />;
        } else if (part.startsWith('[') && part.endsWith(']')) {
          return <strong key={index}>{part.slice(1, -1)}</strong>;
        } else {
          return <span key={index}>{part}</span>;
        }
      })}
    </span>
  );
}

export default function Home() {

  const [lightbox, setLightbox] = useState(null);

  return (
    <div className="home">

      <div className="home-title">
        <img className="home-maintitle" src="/images/invocaster-logo.png" alt="Invocaster logo"/>
        <div className="home-subtitle">{ read('home/subtitle') }</div>
      </div>
      
      <div className="catch-phrase">{ read('home/catchphrase') }</div>
      
      <img className="main-image" src="/images/invocaster-picture-1.png" alt="Invocaster game board"/>
      
      <div className="game-stats">
        <div className="game-stat" id="game-stat-players">
          <img className="game-stat-icon" src="/images/icone-joueurs.png" alt="Player count icon"/>
          <div className="game-stat-text">{"2/4"}</div>
        </div>
        <div className="game-stat" id="game-stat-time">
          <img className="game-stat-icon" src="/images/icone-temps.png" alt="Game time icon"/>
          <div className="game-stat-text">{"30-60"}</div>
        </div>
        <div className="game-stat" id="game-stat-age">
          <img className="game-stat-icon" src="/images/icone-age.png" alt="Player age icon"/>
          <div className="game-stat-text">{"14+"}</div>
        </div>
        <div className="game-stat" id="game-stat-difficulty">
          <img className="game-stat-icon" src="/images/icone-mechanique.png" alt="Player difficulty icon"/>
          <div className="game-stat-text">{ read('home/intermediatedifficulty') }</div>
        </div>
      </div>

      <div className="main-quote">
        <img className="main-quote-background" src="/images/quote.png" alt="Quotation marks"/>
        <div className="main-quote-line">{ read('home/mainquote1') }</div>
        <div className="main-quote-line">{ read('home/mainquote2') }</div>
        <div className="main-quote-line">{ read('home/mainquote3') }</div>
      </div>

      <Separator>{ read('home/howtoplay') }</Separator>

      <div className="rule-section row">
        <FadeIn left>
          <div className="left-section">
            <div className="rule-text">{ format(read('rules/text1')) }</div>
            <div className="rule-text">{ format(read('rules/text2')) }</div>
          </div>
        </FadeIn>
        <FadeIn right>
          <div className="right-section">
            <img className="rule-image" src="/images/sorciers.png" alt="Summoners"/>
          </div>
        </FadeIn>
      </div>

      <FadeIn center>
        <div className="rule-section">
          <div className="top-section">
            <img className="rule-image" src="/images/common-pool.png" alt="Common pool"/>
          </div>
          <div className="bottom-section">
            <div className="rule-text">{ format(read('rules/text3')) }</div>
          </div>
        </div>
      </FadeIn>

      <div className="rule-section row">
        <FadeIn left>
          <div className="left-section">
            <div className="rule-text">{ format(read('rules/text4')) }</div>
            <div className="rule-text">{ format(read('rules/text5')) }</div>
          </div>
        </FadeIn>
        <FadeIn right>
          <div className="right-section">
            <img className="rule-image" src="/images/customization.png" alt="Customization options"/>
          </div>
        </FadeIn>
      </div>

      <FadeIn center reload>
        <div className="rule-section">
          <div className="rolling-section">
            <img className="rule-image" src="/images/All_creatures_A.png" alt="List of creatures"/>
            <img className="rule-image" src="/images/All_creatures_A.png" alt="List of creatures"/>
          </div>
          <div className="center-section">
            <div className="rule-text">{ format(read('rules/text6')) }</div>
          </div>
          <div className="rolling-section backwards">
            <img className="rule-image" src="/images/All_creatures_B.png" alt="List of creatures"/>
            <img className="rule-image" src="/images/All_creatures_B.png" alt="List of creatures"/>
          </div>
        </div>
      </FadeIn>

      <div className="rule-section row">
        <FadeIn left>
          <div className="left-section">
            <div className="rule-text">{ format(read('rules/text7')) }</div>
          </div>
        </FadeIn>
        <FadeIn right>
          <div className="right-section">
            <img className="rule-image" src="/images/zones.png" alt="Placing zones"/>
          </div>
        </FadeIn>
      </div>

      <FadeIn center>
        <div className="rule-section">
          <div className="top-section">
            <img className="rule-image" src="/images/zones-2.png" alt="Types of zones"/>
          </div>
          <div className="bottom-section">
            <div className="rule-text">{ format(read('rules/text8')) }</div>
          </div>
        </div>
      </FadeIn>

      <div className="rule-section row">
      <FadeIn left>
          <div className="left-section">
            <div className="rule-text">{ format(read('rules/text9')) }</div>
            <div className="rule-text">{ format(read('rules/text10')) }</div>
          </div>
        </FadeIn>
        <FadeIn right>
          <div className="right-section">
            <img className="rule-image" src="/images/actions.png" alt="Taking actions"/>
          </div>
        </FadeIn>
      </div>

      <FadeIn center>
        <div className="rule-section">
          <div className="top-section">
            <img className="rule-image" src="/images/4-players.png" alt="A team of two players on the board"/>
          </div>
          <div className="bottom-section">
            <div className="rule-text">{ format(read('rules/text11')) }</div>
            <div className="rule-text">{ format(read('rules/text12')) }</div>
          </div>
        </div>
      </FadeIn>

      <Separator>{ read('home/latestnews') }</Separator>

      <div className="news">
        <div className="news-list">
          <div className="news-link">
            <a href="https://www.instagram.com/p/DFpaI-3tJf1/" target="_blank" rel="noreferrer">
              <img src="/images/instagram-post-1.jpg" alt="Instagram post 1"/>
            </a>
          </div>
          <div className="news-link">
            <a href="https://www.instagram.com/p/DESKYx_s2zC/" target="_blank" rel="noreferrer">
              <img src="/images/instagram-post-2.jpg" alt="Instagram post 2"/>
            </a>
          </div>
          <div className="news-link">
            <a href="https://www.instagram.com/p/DEAII7htKpz/" target="_blank" rel="noreferrer">
              <img src="/images/instagram-post-3.jpg" alt="Instagram post 3"/>
            </a>
          </div>
          <div className="news-link">
            <a href="https://www.instagram.com/p/DD6hPPGN-Al/" target="_blank" rel="noreferrer">
              <img src="/images/instagram-post-4.jpg" alt="Instagram post 4"/>
            </a>
          </div>
          <div className="news-link">
            <a href="https://www.instagram.com/p/DD2SfmytO9q/" target="_blank" rel="noreferrer">
              <img src="/images/instagram-post-5.jpg" alt="Instagram post 5"/>
            </a>
          </div>
          <div className="news-link">
            <a href="https://www.instagram.com/p/DDxLeluNruE/" target="_blank" rel="noreferrer">
              <img src="/images/instagram-post-6.jpg" alt="Instagram post 6"/>
            </a>
          </div>
        </div>
      </div>

      <Separator>{ read('home/seenat') }</Separator>

      <div className="event-list">
      {
        Array.from({ length: 17 }, (_, i) => 17 - i).map(i =>
          <div key={"event-" + i} className="event" id={"event-" + i}>
            <img src={"/images/festival-" + i + ".png"} alt={"Event " + i}/>
          </div>
        )
      }
      </div>

      <div className="lower-title">
        <img className="lower-maintitle" src="/images/invocaster-logo.png" alt="Invocaster logo"/>
        <div className="lower-subtitle">{ read('home/subtitle') }</div>
      </div>

      <div className="socials">
        <div className="socials-text">{ read('home/socials') }</div>
        <div className="socials-list">
          <div className="social-link">
            <a href="https://www.facebook.com/invocaster" target="_blank" rel="noreferrer">
              <img src="/images/facebook-icon.png" alt="Facebook link"/>
            </a>
          </div>
          <div className="social-link">
            <a href="https://www.instagram.com/invocaster" target="_blank" rel="noreferrer">
              <img src="/images/instagram-icon.png" alt="Facebook link"/>
            </a>
          </div>
          <div className="social-link">
            <a href="https://x.com/Invocaster" target="_blank" rel="noreferrer">
              <img src="/images/x-icon.png" alt="Facebook link"/>
            </a>
          </div>
        </div>
      </div>

      <div className="legal-info">{ format(read('home/contact')) }</div>

      <div className="legal-documents">
        <div className="legal-document" onClick={() => setLightbox("legalnotices")}>{ read('home/legalnotices') }</div>
      </div>

      <div className="legal-info">{ format(read('home/legalinfo')) }</div>

      <Lightbox className="legal-document-details" open={lightbox === "legalnotices"} onClose={() => setLightbox(null)}>
        <h2>{ read('home/legalnotices') }</h2>
        <div className="document-subsection-title">{ read('legalnotices/websiteownership') }</div>
        <div className="document-subsection-paragraph">{ read('legalnotices/websiteownershiptext') }</div>
        <div className="document-subsection-title">{ read('legalnotices/intellectualproperty') }</div>
        <div className="document-subsection-paragraph">{ read('legalnotices/intellectualpropertytext') }</div>
        <div className="document-subsection-title">{ read('legalnotices/externallinks') }</div>
        <div className="document-subsection-paragraph">{ read('legalnotices/externallinkstext') }</div>
        <div className="document-subsection-title">{ read('legalnotices/limitationofliability') }</div>
        <div className="document-subsection-paragraph">{ read('legalnotices/limitationofliabilitytext') }</div>
        <div className="document-subsection-title">{ read('legalnotices/changes') }</div>
        <div className="document-subsection-paragraph">{ read('legalnotices/changestext') }</div>
        <div className="document-subsection-title">{ read('legalnotices/governinglaw') }</div>
        <div className="document-subsection-paragraph">{ read('legalnotices/governinglawtext') }</div>
      </Lightbox>

    </div>
  );
}